//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  mounted() {

  },
  props: {
    // eslint-disable-next-line
    blog: Object,
  },
  methods: {
    getLink(slug) {
      this.linkString = "/blogPage/" + slug;
      return this.linkString;
    },
    shorten(x, y) {
      let blogContent = x;
      if (x.length > 150) {
        blogContent =
          blogContent.substr(0, 120) +
          `<a href="/blogPage/` +
          y +
          `" > Read more...</a></p>`;
        return blogContent;
      } else {
        return blogContent;
      }
    },
  },
};
