//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PortfolioCard from "~/components/portfolios/PortfolioCard";
import Posts from "~/components/posts/Posts";
import GetFeaturedProject from "~/apollo/queries/Project/getFeaturedProject";
import GetFeaturedBlogPosts from "~/apollo/queries/Blog/getFeaturedBlogPosts";
import Waves2 from "~/components/home/waves2";
import GetIntro from "~/apollo/queries/getIntro";
import GetMainMenu from "~/apollo/queries/getMainMenu";
import SearchInput from "~/components/SearchInput";

export default {
  components: {
    PortfolioCard,
    Posts,
    Waves2,
    SearchInput
  },
  layout: "home",
  data() {
    return {
      activePage: "activeOrange",
      indexPage: "indexDisappear",
      showSearch: false,
      showDrop: false,
      scrolled: false,
      // total wheel down
      wheel: 0,
      scrollEnabled: true
    };
  },
  apollo: {
     menu: {
      query: GetMainMenu,
      update(data) {
        return data.menu?.menuItems?.edges;
      },
    },
    page: {
      query: GetIntro,
      update(data) {
        return data.page;
      }
    },
    portfolios: {
      query: GetFeaturedProject,
      update(data) {
        return data.portfolios?.edges;
      },
    },
    posts: {
      query: GetFeaturedBlogPosts,
      update(data) {
        return data.posts?.edges;
      },
    },
  },
  mounted() {
    document.getElementById("pageContents").addEventListener("scroll", this.scrollListener, {passive: false});
    this.track();
    console.log(`GTAG EVENT ${this.$route.fullPath}`);

    document.getElementById("pageContents").addEventListener("wheel", this.wheelListener);
    document.getElementById("topHeader").addEventListener("wheel", this.wheelListener);
    // window.addEventListener("scroll",(e) => {
    //   console.log("WINDOW SCROLL",window.scrollY);
    //
    //   // this does _not_ suppress scrolling (at least in FF)
    //   // e.preventDefault();
    // },false);

    // It can happen that a page loads to some scroll position other than 0,
    // On FF & Chrome (not Safari), page reload will resume previous scroll position,
    // for reasons unknown, the first wheelListener call will snap window to scroll 0,
    // So a reasonable strategy turns out to be sending a tiny fake wheel event
    // to snap the window to 0 and carry on.
    if (window.scrollY) {
      console.log("onMounted, scroll=",window.scrollY);
      // something causes a window scroll to 0 when we fake a wheel event.
      this.wheelListener({wheelDeltaY: -1});
      // fake a wheel event to the current scroll position,
      // _this doesn't work because window will snap to 0!_

      // it can be shown that generally, wheel:scroll is 3:1.
      // const e = {wheelDeltaY: -(window.scrollY*3)};
      // console.log(`fake starting wheel to ${e.wheelDeltaY}`);
      // this.wheelListener(e);
    }

    // Unfortunately the wheel animation doesn't work on mobile.
    // Too bad I didn't check that before spending so much time on it.
    function detectMobileBrowser() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    if (detectMobileBrowser()) {
      this.scrollAnimation();
    }
    // if user doesn't start scrolling in 5 seconds, do it for them
    setTimeout(() => {
      if (this.wheel < 2) {
        this.scrollAnimation();
      }
    },7000);
  },
  beforeDestroy() {
    document.getElementById("pageContents").removeEventListener("scroll", this.scrollListener);
    document.getElementById("pageContents").removeEventListener("wheel", this.wheelListener);
    document.getElementById("topHeader").removeEventListener("wheel", this.wheelListener);
  },
  methods: {
    track() {
      this.$gtag.event("page_view", {
        "page_path": `${this.$route.fullPath}`,
        "send_to": "G-C1WT96B64B"
      });
      this.$gtag.screenview({
        "app_name": "2WAV",
        "screen_name": `${this.$route.fullPath}`,
      });
    },
    scrollAnimation() {
      const delta = -5;
      const scrlr = setInterval(() => {
        const e = {wheelDeltaY: delta};
        this.wheelListener(e);
        if (this.wheel > 2000) {
          clearInterval(scrlr);
        }
      },20);
    },
    scrollUp() {
      document.getElementById("pageContents").scrollTo({
        top: 0,
        behavior: "smooth"
      });
    },
    scrollListener(e) {
      console.log("CONTENTS scroll",document.getElementById("pageContents").scrollTop);
      this.scrolled = document.getElementById("pageContents").scrollTop > 70;
      if (document.getElementById("pageContents").scrollLeft > 0) {
        document.getElementById("pageContents").scrollLeft = 0;
      }
      e.preventDefault();
      e.stopPropagation();
    },
    wheelListener(e) {
      const STEPSIZE = 25;
      console.log("wheelDeltaY",e.wheelDeltaY);
      // wheelDeltaY is negative when scrolling down
      // this.wheel should be positive (makes thinking about anim easier)
      // const _wheel = Math.max(0,this.wheel - e.wheelDeltaY);
      const wheelStack = [];
      const _wheel = -(e.wheelDeltaY);
      const step = _wheel > 0 ? STEPSIZE : -STEPSIZE;
      const stepsCt = Math.floor(Math.abs(_wheel/STEPSIZE));
      const steps = Array(stepsCt);
      steps.fill(step,0,stepsCt);
      const tail = _wheel % STEPSIZE;
      if (tail) {
        steps.push(tail);
      }
      // console.log("wheel steps",JSON.stringify(steps));
      wheelStack.push(...steps);
      while (wheelStack.length) {
        const inc = wheelStack.shift();
        const cb = ((inc) => {
          return (time) => {
            this.wheel = Math.max(0, this.wheel + inc);
            // console.log(`set wheel to ${this.wheel}`);
          };
        })(inc);
        window.requestAnimationFrame(cb);
      }

      if (this.wheel >= 0 && this.wheel < 2000) {
        this.enableScroll(false);
      }
      else {
        this.enableScroll(true);
      }
    },
    search() {
      if (this.showSearch === false) {
        this.showSearch = true;
      } else {
        this.showSearch = false;
      }
    },
    getClass(x) {
      const activeClass = this.activePage;
      if (this.$route.fullPath.includes(x.toLowerCase())) {
        return activeClass;
      }
    },
    getWaveClass() {
      const newClass = this.indexPage;
      if (
        !this.$route.fullPath.includes("about") &&
        !this.$route.fullPath.includes("work") &&
        !this.$route.fullPath.includes("connect") &&
        !this.$route.fullPath.includes("people") &&
        !this.$route.fullPath.includes("blog") &&
        !this.$route.fullPath.includes("search")
      ) {
        return newClass;
      }
    },
    showMenu() {
      if (this.showDrop === false) {
        this.showDrop = true;
      } else {
        this.showDrop = false;
      }
    },
    enableScroll(enable) {
      enable = enable !== false;
      if ((enable && this.scrollEnabled) || (!enable && !this.scrollEnabled)) {
        return;
      }
      // this works! no scrollbar, wheel events still work.
      // https://stackoverflow.com/questions/10744542/disable-browser-window-scrolling-of-any-kind
      const bodies = document.getElementsByTagName("body");
      // console.log("body",bodies[0]);
      // const enabled = bodies[0].classList.contains("noscroll");
       if (enable) {
         bodies[0].classList.remove("noscroll");
       }
       else {
         bodies[0].classList.add("noscroll");
       }
       this.scrollEnabled = enable;
    }
  },
};
