//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    // eslint-disable-next-line
    portfolio: Object,
  },
  data() {
    return {
      portfolioContent: "",
      linkString: "",
    };
  },
  mounted() {

  },
  methods: {
    shorten(x) {
      let portfolioContent = x;
      if (portfolioContent.length > 150) {
        portfolioContent = portfolioContent.substr(0, 111) + "..." + "</p>";
        return portfolioContent;
      } else {
        return portfolioContent;
      }
    },
    getLink(slug) {
      this.linkString = "/workPage/" + slug;
      return this.linkString;
    },
  },
};
