import { render, staticRenderFns } from "./index.vue?vue&type=template&id=40e917e6&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=40e917e6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40e917e6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchInput: require('/var/www/2wav.com/nuxt/components/SearchInput.vue').default,Posts: require('/var/www/2wav.com/nuxt/components/posts/Posts.vue').default})
